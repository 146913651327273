(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/campaign-flerp/assets/javascripts/campaign-flerp.js') >= 0) return;  svs.modules.push('/components/lb-ui/campaign-flerp/assets/javascripts/campaign-flerp.js');
"use strict";


const {
  ReactIcon
} = svs.ui;
const {
  campaignIsAvailable,
  campaignUrl,
  reward,
  currentStepIndex
} = svs.lb_ui.campaign_flerp.data.campaignFlerp;
const {
  CampaignStep
} = svs.components.lbUi.campaignStep;
const CampaignFlerp = _ref => {
  let {
    isLightMode,
    branding,
    hideLink
  } = _ref;
  if (!campaignIsAvailable) {
    return null;
  }
  const isLoggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  const steps = ['Bli kund', 'Sätt in', 'Spela', 'Få bonus'];
  const Icon = () => React.createElement(ReactIcon, {
    color: "fc-".concat(isLightMode ? 'navy' : 'white'),
    icon: "game-package",
    size: "500"
  });
  return React.createElement("div", {
    className: "campaign-flerp".concat(isLightMode ? ' campaign-flerp-light' : '', " padding campaign-flerp-").concat(branding || 'default', " f-500")
  }, React.createElement("div", {
    className: "campaign-flerp-text-container"
  }, React.createElement(Icon, null), React.createElement("span", {
    className: "campaign-flerp-text f-left ".concat(isLoggedIn ? '' : 'campaign-flerp-text-single-line')
  }, "Bli kund och f\xE5 ".concat(reward, " kr i bonus. "), isLoggedIn && " Din bonus betalas ut vid avgjort sportspel. ", !hideLink && React.createElement("a", {
    className: "campaign-flerp-link f-medium",
    href: campaignUrl
  }, " L\xE4s mer"))), isLoggedIn && React.createElement("div", {
    className: "campaign-step-container"
  }, React.createElement(CampaignStep, {
    branding: branding,
    currentStepIndex: currentStepIndex,
    steps: steps
  })));
};
setGlobal('svs.components.lbUi.campaignFlerp.CampaignFlerp', CampaignFlerp);

 })(window);